import { MutableRefObject, useMemo, useRef } from "react";

import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import {
  DataGridPro,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  GridApiPro,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridCsvExportMenuItem,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridPinnedColumnFields,
  GridProSlotsComponent,
  GridRowSelectionModel,
  GridRowsProp,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { GridProSlotProps } from "@mui/x-data-grid-pro/models/gridProSlotProps";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import _ from "lodash";

import EmptyState from "../EmptyState/EmptyState";

import { useColumnPreferences } from "./useColumnPreferences";

type Pagination = {
  enabled: boolean;
  rowCount: number | undefined;
  pageSizeOptions: number[];
  model: GridPaginationModel;
  onPaginationModelChange: (model: GridPaginationModel) => void;
};

type ToolbarFilter = {
  handleFilterChange: (model: GridFilterModel) => void;
  filterModel?: GridFilterModel;
  enableColumnFilter?: boolean;
  singleFilter?: boolean;
  oneFilterPerColumn?: boolean;
};

function Toolbar(props: GridToolbarProps) {
  const { csvOptions } = props;

  return (
    <GridToolbarContainer
      sx={{ justifyContent: "space-between", display: "flex", mt: 1 }}
    >
      <Box px={1}>
        <GridToolbarQuickFilter variant="outlined" size="small" />
      </Box>

      <Stack
        direction="row"
        display="flex"
        justifyContent={"flex-end"}
        sx={{ width: { xs: "100%", sm: "auto" } }}
        gap={2}
      >
        <GridToolbarFilterButton />
        {csvOptions && (
          <GridToolbarExportContainer>
            <GridCsvExportMenuItem
              options={{ fileName: csvOptions.fileName }}
            />
          </GridToolbarExportContainer>
        )}
        <GridToolbarColumnsButton />
      </Stack>
    </GridToolbarContainer>
  );
}

function getDefaultMobileColumns(
  columns: GridColDef[],
  defaultColumns: string[]
): GridColumnVisibilityModel {
  const result: GridColumnVisibilityModel = {};

  columns.forEach((column) => {
    result[column.field] = defaultColumns.includes(column.field);
  });
  return result;
}

// The following two functions are for only showing one filter per column in the filter panel.
// Refer to: https://mui.com/x/react-data-grid/filtering/multi-filters/#one-filter-per-column
const filterColumns = ({
  field,
  columns,
  currentFilters,
}: FilterColumnsArgs) => {
  const filteredFields = currentFilters?.map((item) => item.field);
  return columns
    .filter(
      (colDef) =>
        colDef.filterable &&
        (colDef.field === field || !filteredFields.includes(colDef.field))
    )
    .map((column) => column.field);
};

const getColumnForNewFilter = ({
  currentFilters,
  columns,
}: GetColumnForNewFilterArgs) => {
  const filteredFields = currentFilters?.map(({ field }) => field);
  const columnForNewFilter = columns
    .filter(
      (colDef) => colDef.filterable && !filteredFields.includes(colDef.field)
    )
    .find((colDef) => colDef.filterOperators?.length);
  return columnForNewFilter?.field ?? null;
};

export default function DataGridTable({
  id,
  apiRef,
  columns,
  rows,
  loading,
  pagination,
  sortMode = "server",
  handleSortChange,
  handleSelectionChange,
  toolbarFilter,
  downloadFileName,
  pinnedColumns,
  defaultMobileColumns,
  handleRowUpdate,
  isCellEditable,
}: {
  id: string;
  apiRef: MutableRefObject<GridApiPro>;
  columns: GridColDef[];
  rows: GridRowsProp;
  loading: boolean;
  pagination?: Pagination;
  sortMode?: "client" | "server";
  handleSortChange?: (model: GridSortModel) => void;
  handleSelectionChange?: (
    model: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => void;
  toolbarFilter?: ToolbarFilter;
  downloadFileName?: string;
  pinnedColumns?: GridPinnedColumnFields;
  defaultMobileColumns?: string[];
  handleRowUpdate?: (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel
  ) => GridValidRowModel | Promise<GridValidRowModel>;
  isCellEditable?: ((params: GridCellParams) => boolean) | undefined;
}) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const { loadingColWidth } = useColumnPreferences(apiRef, id);

  // If the value rowCount becomes undefined during loading, the current page is reset to zero.
  // To avoid this, we memoize the rowCount value to ensure it doesn't change during loading.
  // Refer to: https://mui.com/x/react-data-grid/pagination/#server-side-pagination
  const rowCountRef = useRef(pagination?.rowCount || 0);
  const rowCount = useMemo(() => {
    const currentCount = pagination?.rowCount;
    if (currentCount !== undefined) {
      rowCountRef.current = currentCount;
    }

    return rowCountRef.current;
  }, [pagination?.rowCount]);

  const slots: Partial<GridProSlotsComponent> = {};
  const slotProps: GridProSlotProps = {};
  if (pagination) {
    slotProps.pagination = {
      showFirstButton: true,
      showLastButton: true,
    };
  }
  if (toolbarFilter || downloadFileName) {
    slots.toolbar = Toolbar;

    slotProps.toolbar = {
      showQuickFilter: Boolean(toolbarFilter?.handleFilterChange),
      csvOptions: downloadFileName ? { fileName: downloadFileName } : undefined,
    };

    slotProps.filterPanel = {
      disableAddFilterButton: toolbarFilter?.singleFilter, // Disable the default "Add Filter" button in the filter panel
    };

    if (toolbarFilter?.oneFilterPerColumn) {
      // Limit to only one filter per column with the _and operator only.
      slotProps.filterPanel = {
        filterFormProps: {
          filterColumns,
        },
        getColumnForNewFilter,
        logicOperators: [GridLogicOperator.And],
      };
    }
  }

  const initialState: GridInitialStatePro = {
    pinnedColumns: pinnedColumns,
  };
  if (defaultMobileColumns && xs) {
    initialState.columns = {
      columnVisibilityModel: getDefaultMobileColumns(
        columns,
        defaultMobileColumns
      ),
    };
  }

  return (
    // The DataGridPro component is wrapped in a Box with flex to apply "autoHeight" styling.
    <Box sx={{ minHeight: 400, display: "flex", flexDirection: "column" }}>
      <DataGridPro
        apiRef={apiRef}
        initialState={initialState}
        rows={rows}
        columns={columns}
        loading={loading || loadingColWidth}
        disableRowSelectionOnClick
        pagination={pagination?.enabled}
        paginationMode={pagination ? "server" : "client"}
        rowCount={pagination ? rowCount : undefined}
        pageSizeOptions={pagination?.pageSizeOptions}
        paginationModel={pagination?.model}
        onPaginationModelChange={pagination?.onPaginationModelChange}
        slots={{
          ...slots,
          noRowsOverlay: () => <EmptyState mt={10} />,
        }}
        slotProps={slotProps}
        sortingMode={sortMode}
        disableColumnSorting={!handleSortChange}
        onSortModelChange={handleSortChange}
        disableColumnFilter={!toolbarFilter?.enableColumnFilter}
        filterMode={"server"}
        filterModel={toolbarFilter?.filterModel ?? undefined}
        onFilterModelChange={
          toolbarFilter?.handleFilterChange
            ? _.debounce(toolbarFilter.handleFilterChange, 200)
            : undefined
        }
        checkboxSelection={Boolean(handleSelectionChange)}
        keepNonExistentRowsSelected={Boolean(handleSelectionChange)}
        onRowSelectionModelChange={handleSelectionChange}
        processRowUpdate={handleRowUpdate}
        isCellEditable={isCellEditable}
        sx={{
          "& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
            "&:empty::before": {
              content: '"--"',
            },
          },
        }}
      />
    </Box>
  );
}
