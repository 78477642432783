import { MutableRefObject, useEffect, useState } from "react";

import { GridApiPro, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";

import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from "../../lib/localStorage";

export const useColumnPreferences = (
  apiRef: MutableRefObject<GridApiPro> | undefined,
  id: string | undefined
) => {
  const [loadingColWidth, setLoadingColWidth] = useState(true);
  const [hasSavedColWidth, setHasSavedColWidth] = useState(false);

  useEffect(() => {
    if (!apiRef) return;

    const unsubscribeAutosizeColumns = apiRef.current.subscribeEvent(
      "rowsSet",
      () => {
        if (!apiRef || hasSavedColWidth) return;
        // Waiting for MUI to perform data processing when recieving new rows
        // Ideally, this can be improved when MUI provides an event for letting us know
        // when the data processing is done
        setTimeout(() => {
          if (!apiRef?.current || hasSavedColWidth) return;
          apiRef.current.autosizeColumns({
            includeHeaders: true,
            includeOutliers: true,
          });
          setLoadingColWidth(false);
        }, 500);
      }
    );

    return () => {
      unsubscribeAutosizeColumns?.();
    };
  }, [apiRef, hasSavedColWidth]);

  useEffect(() => {
    if (!apiRef) return;

    const unsubscribeColWidth = apiRef.current.subscribeEvent(
      "columnResizeStop",
      () => {
        if (!apiRef || !id) return;

        // Save the column width for all columns in the table if a user rezises any column
        const updatedCols = apiRef.current.getAllColumns?.().map((col) => ({
          field: col.field,
          width: col.width,
        }));

        saveToLocalStorage(`table-${id}-colSize`, updatedCols);
      }
    );

    return () => {
      unsubscribeColWidth?.();
    };
  }, [apiRef, id]);

  useEffect(() => {
    if (!apiRef || !id) return;

    const savedCols = loadFromLocalStorage(`table-${id}-colSize`);

    if (savedCols?.length) {
      // Apply saved column sizes
      setHasSavedColWidth(true);

      savedCols.forEach(
        ({ field, width }: { field: string; width: number }) => {
          apiRef.current.setColumnWidth?.(field, width);
        }
      );
      setLoadingColWidth(false);
    }
  }, [apiRef, id]);

  useEffect(() => {
    if (!apiRef) return;

    const unsubscribeColVisibility = apiRef.current.subscribeEvent(
      "columnVisibilityModelChange",
      (model: GridColumnVisibilityModel) => {
        saveToLocalStorage(`table-${id}-colVisibility`, model);
      }
    );

    return () => {
      unsubscribeColVisibility?.();
    };
  }, [apiRef, id]);

  useEffect(() => {
    if (!apiRef) return;

    const savedModel = loadFromLocalStorage(`table-${id}-colVisibility`);

    if (!savedModel) return;
    apiRef.current.setColumnVisibilityModel?.(savedModel);
  }, [apiRef, id]);

  return { loadingColWidth };
};
