import { Box, Stack, Typography } from "@mui/material";

import NeedHelpContactSupport from "./NeedHelpContactSupport";

export default function EmptyState({ mt = 25 }: { mt?: number }) {
  return (
    <Stack>
      <Box display={"flex"} justifyContent={"center"} mt={mt}>
        <Typography variant="h6">No data to display.</Typography>
      </Box>
      <Box textAlign="center" mt={2}>
        <NeedHelpContactSupport />
      </Box>
    </Stack>
  );
}
